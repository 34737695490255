import React, {Component} from "react"
import {graphql, Link} from "gatsby"

import imgPage from "../images/headers/Logos_Mex_Jet.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import style from "./mexjet.module.scss"
import Header from "../components/header";
import Img from "gatsby-image"
import {GetList} from "../hooks/jetsList";

import Equipaje from "../images/mexjet/icon_equipaje.svg"
import Passajeros from "../images/mexjet/icon_pasajeros.svg"

import JetCard from "../images/icons/jetcard.png"
import Equity from "../images/icons/equity.png"
import videoEquity from "../video/equity.mp4"
//SECURITY
import Wingman from "../images/icons/wingman_pro.png"
import Isbao from "../images/icons/ISBAO.png"

import Form from "../components/form";
import "video-react/dist/video-react.css"

import {ControlBar, Player} from "video-react";
//import VideoCovid from "../video/Master_AleCovid19_Draft2.mp4";

export default ({data}) => {
    const jetList = GetList();
    const extraInfo =
        [
            {name: '', mail: 'info@ale.mx', phone: '554 202 0920'},
        ];
    return (
        <Layout extraInfo={extraInfo}>
            <Header LogoPage={imgPage}/>
            <SEO title="MexJet"/>
            <Img fluid={data.portada.childImageSharp.fluid}/>
            <Form options={['MexJet: Fractional Aircraft/Jetcards (hrs)']}/>
            <div className={style.jetServices}>
                <div className={style.card}>
                    <img src={JetCard} alt="JetCard"/>
                    <p>Adquiera únicamente las horas que usted necesita sin un límite de tiempo y con la posibilidad
                        de intercambiar a equipos de otras características.</p>
                    <a href="#jetcard">Conozca Más</a>
                </div>
                <div className={style.equity}>
                    <img src={Equity} alt="Equity"/>
                    <p>Equity permite poseer todo lo mejor de la aeronave deseada, por sólo una fracción del activo
                        y de los costos fijos de operación.</p>
                    <a href="#equity">Conozca Más</a>
                </div>
            </div>

            <PopCovid/>

            <div className={style.jetOur}>
                <h2>Nuestra flota</h2>

                <div className={style.items}>
                    {jetList.map((item) => {

                        return (
                            <div className={`${style.item}`} key={item.node.frontmatter.slug}>
                                <div className={style.left}>
                                    <Img style={{maxHeight: '270px'}}
                                         fluid={item.node.frontmatter.thumbnail.childImageSharp.fluid}/>
                                    <p>{item.node.frontmatter.title}</p>

                                </div>

                                <div className={style.right}>
                                    <div className={style.images}>
                                        <Passajeros/>
                                        <Equipaje/>
                                        <p>
                                            <span>{item.node.frontmatter.pasajeros}</span>
                                            <span>{item.node.frontmatter.equipaje}</span>
                                        </p>
                                    </div>
                                    {(item.node.frontmatter.title !== "Citation M2 GEN2" && item.node.frontmatter.title  !== 'Citation CJ3+') ?
                                        <Link to={item.node.frontmatter.slug}>Conozca Más</Link>
                                        :
                                        <p className={style.soon}>Próximamente</p>
                                    }
                                </div>


                            </div>
                        )

                    })}
                </div>
            </div>

            <div className={style.jetImages}>

                <div className={style.title} id="jetcard">
                    <p>Jet Card</p>
                </div>
                <div className={`${style.infoBlock} ${style.logo}`}>
                    <img src={JetCard} alt="JetCard"/>
                    <div className={style.info}>
                        <ul>
                            <p><b>Valoramos tu tiempo</b></p>
                            <p>Jetcard de Mexjet, fue el primer programa de pre pago de horas de vuelo lanzado en
                                México. Tras su lanzamiento en 1997, como pionero de la aviación fraccionada en México,
                                hemos perfeccionado nuestra Jetcard para ser el producto de entrada a la aviación
                                ejecutiva, adquiriendo únicamente las horas que usted necesita, y debitando las mismas
                                sin un límite de tiempo y con la posibilidad de intercambiar a equipos de otras
                                características y elegir un programa para viajes de negocios o placer.</p>
                        </ul>
                    </div>
                </div>
                <div className={style.title} id="equity">
                    <p>Equity</p>
                </div>
                <div className={style.infoBlock}>
                    <div className={style.info}>
                        <ul>
                            <p><b>Fraccionando tiene más valor</b></p>
                            <p>Para quien poseé una aeronave, o pretende adquirir una, Mexjet Equity permite invertir en
                                un activo de mejores características (e.g. más grande, de mayor rango, más nuevo, etc.),
                                que el operado actualmente o el que se desea adquirir. Los costos fijos, inherentes a la
                                operación de cualquier aeronave, encarecen el costo por hora de vuelo cuando las horas
                                utilizadas anualmente son pocas; Mexjet Equity permite poseer todo lo mejor de la
                                aeronave deseada, por sólo una fracción del activo y de los costos fijos de
                                operación.</p>
                        </ul>
                    </div>
                    <div className={style.img}>
                        <img src={Equity} alt="equity"/>
                        <VideoEquity/>
                    </div>
                </div>
                <div className={style.title}>
                    <p>Seguridad</p>
                </div>
                <div className={style.jetSecurity}>
                    <p><b>Su seguridad es nuestra prioridad</b></p>
                    <p>Realizamos una inversión constante para ofrecerle una operación 100% segura, con las siguientes
                        certificaciones:</p>
                    <div className={style.logos}>
                        <div className="item">
                            <a href="https://ibac.org/is-bao" target="_blank" rel="noopener noreferrer">
                                <img src={Isbao} alt="IS-BAO"/>
                            </a>
                        </div>
                        
                        <div id="wyvern">
                            <a href="https://app.wyvern.systems/public/directory/wingman" target="_blank"
                               rel="noopener noreferrer">
                                <img
                                    src={Wingman} alt="WYVERN Click-to-Verify"/>
                                <p>Click-to-Verify</p>
                            </a>
                        </div>

                    </div>
                </div>

                <div className={style.title}>
                    <p>BASES DE OPERACIÓN</p>
                </div>
                <div className={style.jetSecurity}>
                <p>
                    <ul>
                        <li>Toluca</li>
                        <li>Monterrey</li>
                        <li>Guadalajara</li>
                        <li>León</li>
                        <li>Mérida</li>
                        </ul>
                        </p> 
                </div>
            </div>

        </Layout>
    )
}

export class PopCovid extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            vidStatus: false,
            player: null,
            active: true,
            playing: false
        };

        this.startVideo = this.startVideo.bind(this);
        this.onEnded = this.onEnded.bind(this);
        this.closeAlert = this.closeAlert.bind(this)
    }

    closeAlert() {
        this.setState({active: false})
        this.player.pause();
    }

    startVideo() {
        this.setState({vidStatus: true});
        this.player.play();
    }

    onEnded() {
        this.closeAlert();
    }

    render() {
        return (
            <div className={this.state.active ? `${style.popUpVideo} ${style.active}` : style.popUpVideo}>

            </div>
        )
    }
}

export class VideoEquity extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            vidStatus: false,
            player: null
        };
        this.startVideo = this.startVideo.bind(this);
        this.onEnded = this.onEnded.bind(this);
    }


    startVideo() {
        this.setState({vidStatus: true});
        this.player.play();
    }

    onEnded() {
        this.setState({vidStatus: false})
    }

    render() {
        return (
            <Player
                poster={Equity}
                onEnded={this.onEnded}
                className={this.state.vidStatus === true ? `${style.video} ${style.active}` : `${style.video}`}
                src={videoEquity}
                ref={player => {
                    this.player = player;
                }}
            >
                <ControlBar disableCompletely={false}/>
                <button className={style.button} onClick={this.startVideo}>Play Video</button>
            </Player>
        )
    }

}

export const query = graphql`   
    query ImagesMexJet {
    portada: file(relativePath: {eq: "mexjet/foto_portada.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
   
}`;





